import styled from "styled-components";
import React from "react";
import {StaticImage} from "gatsby-plugin-image";
import {ButtonUI, TypographyUI} from "@ui";
import {LAPTOP_MOBILE_POINTER} from "@tools/constants";
import {AdaptiveLinkCt} from "@components";

export const TopSection = () => (
    <TopSectionContainer>
        <TopSectionContent>

            <TopSectionTitle
                swichPoint={LAPTOP_MOBILE_POINTER}
                variant="h1"
            >Все вакансии для самозанятых в одном месте
            </TopSectionTitle>
            <TopSectionDescription
            >Здесь вы найдете заказы из любой отрасли. Скачайте приложение, заполните профиль и приступайте к работе
            </TopSectionDescription>

            <AdaptiveLinkCt to="https://market.selfwork.ru">
                <TopSectionBtn>
                    Попробовать
                </TopSectionBtn>
            </AdaptiveLinkCt>

        </TopSectionContent>

        <TopSectionImage>
            <StaticImage
                src="../../../images/selfworkers/TopSectionImage.png"
                loading="eager"
                placeholder="none"
                formats={["auto", "webp"]}
                width={522}
                height={506}
                layout="fixed"
                objectFit="contain"
                alt=""
            />
        </TopSectionImage>


    </TopSectionContainer>
);

const TopSectionContainer = styled.section`
    display: flex;
    margin-top: 78px;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: 453px;
    padding: 45px;
    border-radius: 16px;
    background: var(--background-second);
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        flex-direction: column;
        margin: 40px -19px 0px;
        padding: 30px 20px;
        height: 100%;
    }
    @media (max-width: 609px) {
        border-radius: 0px;
    }
`;

const TopSectionContent = styled.div`
    width: 560px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        width: 100%;
    }
`;

const TopSectionTitle = styled(TypographyUI.H1)`
    span {
        color: var(--accent-main);
    }

    margin-bottom: 16px;
`;

const TopSectionDescription = styled(TypographyUI.Body1Regular)`
    color: var(--text-secondary);
    margin-bottom: 48px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        margin-bottom: 40px;
    }
`;

const TopSectionImage = styled.div`
    position: absolute;
    right: 45px;
    bottom: 0;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        display: none;
    }
`;

const TopSectionBtn = styled(ButtonUI)`
    width: 240px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        width: 100%;
    }
`;
