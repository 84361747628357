import styled from "styled-components";
import React from "react";
import {StaticImage} from "gatsby-plugin-image";
import {ButtonUI, TypographyUI} from "@ui";
import {LAPTOP_MOBILE_POINTER} from "@tools/constants";
import {useWindowSize} from "@tools/hooks";

export const HelpSection = () => {

    const { width } = useWindowSize();

    return (
        <HelpSectionContainer>
            <HelpSectionTitleContainer>
                    <HelpSectionTitle swichPoint={LAPTOP_MOBILE_POINTER}>Сосредоточьтесь на работе — мы поможем с заказами и документами</HelpSectionTitle>
                    <HelpSectionDescription swichPoint={LAPTOP_MOBILE_POINTER}>Маркетплейс — это площадка по поиску подработки для самозанятых. С ней вы составите договор без ошибок, найдете клиентов и полностью автоматизируете получение дохода</HelpSectionDescription>
            </HelpSectionTitleContainer>

            <HelpSectionItems>

                <HelpSectionItem style={{'width': width && width > LAPTOP_MOBILE_POINTER ? '755px' : '100%', 'height': width && width > LAPTOP_MOBILE_POINTER ? '424px' : '100%', 'background': 'var(--background-main-grey)'}}>
                    <HelpSectionItemInfo style={{'maxWidth': width && width > LAPTOP_MOBILE_POINTER ? '50%' : '100%'}}>
                        <TypographyUI.H3 className="title">Разные задачи</TypographyUI.H3>
                        <TypographyUI.Body1Regular className="description">Отправьтесь на прогулку с собакой, почините кран или придумайте текст посадочной страницы — в Маркетплейсе вы найдете работу по душе</TypographyUI.Body1Regular>
                    </HelpSectionItemInfo>
                    <HelpSectionItemImage style={{'position': width && width > LAPTOP_MOBILE_POINTER ? 'absolute' : 'relative', 'bottom': '15px', 'right': width && width > LAPTOP_MOBILE_POINTER ? '0px' : '0', 'width': width && width > LAPTOP_MOBILE_POINTER ? '390px' : '100%'}}>
                        <StaticImage
                            src="../../../images/selfworkers/HelpSectionImage1.png"
                            loading="eager"
                            placeholder="none"
                            formats={["auto", "webp"]}
                            layout="constrained"
                            objectFit="contain"
                            alt=""
                        />
                    </HelpSectionItemImage>
                </HelpSectionItem>

                <HelpSectionItem style={{'width': width && width > LAPTOP_MOBILE_POINTER ? '365px' : '100%', 'height': width && width > LAPTOP_MOBILE_POINTER ? '424px' : '100%', 'background': 'var(--background-second)'}}>
                    <HelpSectionItemInfo>
                        <TypographyUI.H3 className="title">Бесплатная реклама услуг</TypographyUI.H3>
                        <TypographyUI.Body1Regular className="description">Выполняйте работу добросовестно, чтобы подняться в рейтинге самозанятых — чем он лучше, тем выше профиль в списке</TypographyUI.Body1Regular>
                    </HelpSectionItemInfo>
                    <HelpSectionItemImage style={{'position': width && width > LAPTOP_MOBILE_POINTER ? 'absolute' : 'relative', 'bottom': '0', 'left': width && width > LAPTOP_MOBILE_POINTER ? '0' : '0', 'right': width && width > LAPTOP_MOBILE_POINTER ? '0' : '0', 'width': width && width > LAPTOP_MOBILE_POINTER ? '100%' : '100%'}}>
                        <StaticImage
                            src="../../../images/selfworkers/HelpSectionImage2.png"
                            loading="eager"
                            placeholder="none"
                            formats={["auto", "webp"]}
                            layout="constrained"
                            objectFit="contain"
                            alt=""
                        />
                    </HelpSectionItemImage>
                </HelpSectionItem>

                <HelpSectionItem style={{'width': width && width > LAPTOP_MOBILE_POINTER ? '555px' : '100%', 'height': width && width > LAPTOP_MOBILE_POINTER ? '438px' : '100%', 'background': 'var(--background-second)'}}>
                    <HelpSectionItemInfo>
                        <TypographyUI.H3 className="title">Автоматическое создание документов</TypographyUI.H3>
                        <TypographyUI.Body1Regular className="description">Маркетплейс предоставит договор, акты, счета и чеки. А еще поможет обменяться подписями и оригиналами документов</TypographyUI.Body1Regular>
                    </HelpSectionItemInfo>
                    <HelpSectionItemImage style={{'position': width && width > LAPTOP_MOBILE_POINTER ? 'absolute' : 'relative', 'bottom': '0', 'left': width && width > LAPTOP_MOBILE_POINTER ? '0' : '', 'right': width && width > LAPTOP_MOBILE_POINTER ? '0' : '', margin: '0 auto', 'width': width && width > LAPTOP_MOBILE_POINTER ? '331px' : '100%'}}>
                        <StaticImage
                            src="../../../images/selfworkers/HelpSectionImage3.png"
                            loading="eager"
                            placeholder="none"
                            formats={["auto", "webp"]}
                            layout="constrained"
                            objectFit="contain"
                            alt=""
                        />
                    </HelpSectionItemImage>
                </HelpSectionItem>

                <HelpSectionItem style={{'width': width && width > LAPTOP_MOBILE_POINTER ? '565px' : '100%', 'height' : width && width > LAPTOP_MOBILE_POINTER ? '438px' : '100%', 'background': 'var(--background-main-grey)'}}>
                    <HelpSectionItemInfo>
                        <TypographyUI.H3 className="title">Полная интеграция с приложением «Мой налог»</TypographyUI.H3>
                        <TypographyUI.Body1Regular className="description">Вся информация о доходах автоматически отправляем в «Мой налог»: вам не нужно помнить о чеках</TypographyUI.Body1Regular>
                    </HelpSectionItemInfo>
                    <HelpSectionItemImage style={{'position': width && width > LAPTOP_MOBILE_POINTER ? 'absolute' : 'relative', 'bottom': width && width > LAPTOP_MOBILE_POINTER ? '35px': '0', 'left': '0','right': '0', 'width': '100%', 'maxWidth': width && width > LAPTOP_MOBILE_POINTER ? '391px': null, 'margin': '0 auto' }}>
                        <StaticImage
                            src="../../../images/selfworkers/HelpSectionImage4.png"
                            loading="eager"
                            placeholder="none"
                            formats={["auto", "webp"]}
                            layout="constrained"
                            objectFit="contain"
                            alt=""
                        />
                    </HelpSectionItemImage>
                </HelpSectionItem>

                <HelpSectionItem style={{'width': width && width > LAPTOP_MOBILE_POINTER ? '755px' : '100%', 'height': width && width > LAPTOP_MOBILE_POINTER ? '424px' : '100%', 'background': 'var(--background-second)'}}>
                    <HelpSectionItemInfo style={{'maxWidth': width && width > LAPTOP_MOBILE_POINTER ? '42%' : '100%'}}>
                        <TypographyUI.H3 className="title">Заказчики со всей России</TypographyUI.H3>
                        <TypographyUI.Body1Regular className="description">Клиент может предложить самозанятому удаленные вакансии или офлайн-проект в любом из городов РФ</TypographyUI.Body1Regular>
                    </HelpSectionItemInfo>
                    <HelpSectionItemImage style={{'position': width && width > LAPTOP_MOBILE_POINTER ? 'absolute' : 'relative', 'bottom': '15px', 'right': width && width > LAPTOP_MOBILE_POINTER ? '0px' : '0', 'width': width && width > LAPTOP_MOBILE_POINTER ? '390px' : '100%'}}>
                        <StaticImage
                            src="../../../images/selfworkers/HelpSectionImage5.png"
                            loading="eager"
                            placeholder="none"
                            formats={["auto", "webp"]}
                            layout="constrained"
                            objectFit="contain"
                            alt=""
                        />
                    </HelpSectionItemImage>
                </HelpSectionItem>

                <HelpSectionItem style={{'width': width && width > LAPTOP_MOBILE_POINTER ? '365px' : '100%', 'height': width && width > LAPTOP_MOBILE_POINTER ? '424px' : '100%', 'background': 'var(--background-second)'}}>
                    <HelpSectionItemInfo>
                        <TypographyUI.H3 className="title">Оплата любым удобным способом</TypographyUI.H3>
                        <TypographyUI.Body1Regular className="description">Получите деньги за выполненную работу наличными, на карту любого банка или электронный кошелек</TypographyUI.Body1Regular>
                    </HelpSectionItemInfo>
                    <HelpSectionItemImage style={{'position': width && width > LAPTOP_MOBILE_POINTER ? 'absolute' : 'relative', 'bottom': '0', 'left': width && width > LAPTOP_MOBILE_POINTER ? '0' : '0', 'right': width && width > LAPTOP_MOBILE_POINTER ? '0' : '0', 'width': width && width > LAPTOP_MOBILE_POINTER ? '100%' : '100%'}}>
                        <StaticImage
                            src="../../../images/selfworkers/HelpSectionImage6.png"
                            loading="eager"
                            placeholder="none"
                            formats={["auto", "webp"]}
                            layout="constrained"
                            objectFit="contain"
                            alt=""
                        />
                    </HelpSectionItemImage>
                </HelpSectionItem>

            </HelpSectionItems>

        </HelpSectionContainer>
    )
};

const HelpSectionContainer = styled.section`
    display: flex;
    margin-top: 160px;
    align-items: center;
    justify-content: space-between;
    position: relative;
    flex-direction: column;
    margin-bottom: 100px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        margin: 100px -19px 20px;
    }
`;

const HelpSectionTitleContainer = styled.div`
    width: 100%;
    margin-bottom: 60px;
    max-width: 830px;
    text-align: center;
    @media (max-width: 609px) {
        padding: 0 20px;
    }
`;

const HelpSectionTitle = styled(TypographyUI.H2)`
    margin-bottom: 24px;
`;

const HelpSectionDescription = styled(TypographyUI.Body1Regular)`
    color: var(--text-secondary);
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        width: 100%;
    }
`;


const HelpSectionItems = styled.div`
    width: 100%;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
`;

const HelpSectionItem = styled.div`
    padding-top: 40px;
    padding-left: 28px;
    padding-right: 28px;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    @media (max-width: 609px) {
        border-radius: 0px;
    }
`;

const HelpSectionItemInfo = styled.div`
    & .title {
        margin-bottom: 16px;
    }
    & .description {
        color: var(--text-secondary);
    }
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        padding-bottom: 40px;
    }
`;

const HelpSectionItemImage = styled.div`
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        max-width: 300px;
        margin: 0 auto;
        padding: 20px 0 0;
    }
    
`;
