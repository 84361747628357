import styled from "styled-components";
import React from "react";
import {StaticImage} from "gatsby-plugin-image";
import {ButtonUI, TypographyUI} from "@ui";
import {LAPTOP_MOBILE_POINTER} from "@tools/constants";
import {useWindowSize} from "@tools/hooks";
import {LinkSd} from "../../../ui/atoms/LinkUI/components/Root";

export const DownloadMarketSection = () => {

    const {width} = useWindowSize();

    return (
        <DownloadMarketSectionContainer>
        <DownloadMarketSectionItem style={{
            'width': '100%',
            'height': width && width > LAPTOP_MOBILE_POINTER ? '408px' : '100%',
            'background': 'var(--background-main-grey)',
        }}>
            <DownloadMarketSectionItemInfo
                style={{'maxWidth': width && width > LAPTOP_MOBILE_POINTER ? '45%' : '100%'}}>
                <TypographyUI.H2 swichPoint={LAPTOP_MOBILE_POINTER}>Искать подработку — легко</TypographyUI.H2>
                <DownloadMarketSectionItemInfoDescription>Скачайте приложение Маркетплейса и отслеживайте подходящие заказы на смартфоне</DownloadMarketSectionItemInfoDescription>
                <DownloadMarketLinks>
                    <LinkSd target="_blank" href={"https://apps.apple.com/app/id1488581137"}>
                        <StaticImage
                            src="../../../images/shared/app-store.png"
                            loading="eager"
                            placeholder="none"
                            formats={["auto", "webp"]}
                            width={152}
                            height={48}
                            alt="ios-app"
                        />
                    </LinkSd>
                    <LinkSd target="_blank"
                        href={
                            "https://play.google.com/store/apps/details?id=ru.selfwork.marketplace"
                        }
                    >
                        <StaticImage
                            src="../../../images/shared/google-store.png"
                            loading="eager"
                            placeholder="none"
                            formats={["auto", "webp"]}
                            width={152}
                            height={48}
                            alt="ios-app"
                        />
                    </LinkSd>
                </DownloadMarketLinks>

            </DownloadMarketSectionItemInfo>

            <DownloadMarketSectionItemImage style={{
                'display': width && width > LAPTOP_MOBILE_POINTER ? 'block' : 'block',
                'position': width && width > LAPTOP_MOBILE_POINTER ? 'absolute' : 'relative',
                'right': width && width > LAPTOP_MOBILE_POINTER ? '0' : '0',
                'width': width && width > LAPTOP_MOBILE_POINTER ? '646px' : 'auto',
                'top': width && width > LAPTOP_MOBILE_POINTER ? '20px' : '0'
            }}>
                <StaticImage
                    src="../../../images/selfworkers/DownloadMarketSectionImage.png"
                    loading="eager"
                    placeholder="none"
                    formats={["auto", "webp"]}
                    layout="constrained"
                    objectFit="contain"
                    alt=""
                />
            </DownloadMarketSectionItemImage>
        </DownloadMarketSectionItem>
        </DownloadMarketSectionContainer>
    )
};

const DownloadMarketSectionContainer = styled.section`
    margin: 0px 0 100px;
    @media (max-width: 609px) {
        margin: 80px -19px 80px;
    }
`;

const DownloadMarketLinks = styled.div`
    margin-top: 30px;
    display: flex;
    gap: 16px;
`;

const DownloadMarketSectionItem = styled.div`
    padding-top: 40px;
    padding-left: 28px;
    padding-right: 28px;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    @media (max-width: 609px) {
        border-radius: 0px;
    }
`;

const DownloadMarketSectionItemInfo = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
`;

const DownloadMarketSectionItemInfoDescription = styled(TypographyUI.Body1Regular)`
    padding-top: 20px;
`

const DownloadMarketSectionItemImage = styled.div`
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        max-width: 300px;
        margin: 0 auto;
        padding: 20px 0 0;
    }

`;
