import {TypographyUI} from "@ui";
import {LAPTOP_MOBILE_POINTER} from "@tools/constants";
import React from "react";
import {useWindowSize} from "@tools/hooks";
import styled from "styled-components";

import num1Img from "@images/selfworkers/FindOrderSectionImage1.svg";
import num2Img from "@images/selfworkers/FindOrderSectionImage2.svg";
import num3Img from "@images/selfworkers/FindOrderSectionImage3.svg";

export const FindOrderSection = () => {

    const {width} = useWindowSize();

    return (
        <FindOrderSectionContainer>
            <FindOrderSectionTitle swichPoint={LAPTOP_MOBILE_POINTER}>
                Как быстро найти заказ
            </FindOrderSectionTitle>

            <FindOrderSectionItems>
                <FindOrderSectionItem>
                    <FindOrderSectionItemImg
                        src={num1Img}
                        alt="num-1"
                    />
                    <FindOrderSectionItemTitle>
                        Заполните профиль
                    </FindOrderSectionItemTitle>
                    <FindOrderSectionItemDescription>
                        Опишите опыт работы, добавьте портфолио и получите первые отклики от заказчиков
                    </FindOrderSectionItemDescription>
                </FindOrderSectionItem>

                <FindOrderSectionItem>
                    <FindOrderSectionItemImg
                        src={num2Img}
                        alt="num-2"
                    />
                    <FindOrderSectionItemTitle>
                        Ищите задания
                    </FindOrderSectionItemTitle>
                    <FindOrderSectionItemDescription>
                        Воспользуйтесь фильтром для поиска актуальной работы и включите уведомления о появлении новых задач
                    </FindOrderSectionItemDescription>
                </FindOrderSectionItem>

                <FindOrderSectionItem>
                    <FindOrderSectionItemImg
                        src={num3Img}
                        alt="num-3"
                    />
                    <FindOrderSectionItemTitle>
                        Начните сотрудничество
                    </FindOrderSectionItemTitle>
                    <FindOrderSectionItemDescription>
                        Договоритесь о деталях, заключите договор и приступайте к работе
                    </FindOrderSectionItemDescription>
                </FindOrderSectionItem>

            </FindOrderSectionItems>

        </FindOrderSectionContainer>
    )
};

const FindOrderSectionContainer = styled.section`
    margin: 0px 0 160px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        margin: 100px 0 100px;
    }
`;

const FindOrderSectionTitle = styled(TypographyUI.H2)`
    text-align: center;
    margin-bottom: 75px;
`

const FindOrderSectionItems = styled.div`
    display: flex;
    gap: 32px;

    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        flex-direction: column;
    }
`;

const FindOrderSectionItem = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    max-width: 360px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        max-width: 100%;
    }
`;

const FindOrderSectionItemImg = styled.img`
    width: 72px;
    height: 72px;
    margin-bottom: 32px;
`;

const FindOrderSectionItemTitle = styled(TypographyUI.H4Bold)`
    padding-bottom: 12px;
`;

const FindOrderSectionItemDescription = styled(TypographyUI.Body1Regular)`
    color: var(--text-secondary);
`;
