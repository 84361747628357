import React from "react";
import styled from "styled-components";

import {LAPTOP_MOBILE_POINTER} from "@tools/constants";
import { ButtonUI, TypographyUI } from "@ui";

import { StaticImage } from "gatsby-plugin-image";
import {useWindowSize} from "@tools/hooks";
import {AdaptiveLinkCt} from "@components";

export const UsePlatformSection = () => {
    const {width} = useWindowSize();

    return (
        <SectionBoxSd>
            <TypographyUI.H2 swichPoint={LAPTOP_MOBILE_POINTER}
                             $tac className={'title'}>
                Используйте все возможности платформы
            </TypographyUI.H2>
            <TypographyUI.Body1Regular $tac className={'description'}>
                Принимайте деньги на любых площадках: дополнительно и бесплатно вы можете настроить оплату по ссылке в соцсетях или мессенджере
            </TypographyUI.Body1Regular>
            <TryBtn>
                <AdaptiveLinkCt to="https://market.selfwork.ru">
                    <ButtonSd>Попробовать</ButtonSd>
                </AdaptiveLinkCt>
            </TryBtn>


            <BodyBoxSd>
                <ContentBoxSd>
                    <BoxOfImgSd>
                        <StaticImage
                            src="../../../images/selfworkers/UsePlatformSectionImage1.png"
                            alt="link"
                            loading="eager"
                            placeholder="none"
                            formats={["auto", "webp"]}
                            width={65}
                            height={65}
                        />
                    </BoxOfImgSd>
                    <div>
                        <InfoTitleSd>Принимайте оплату в соцсетях и мессенджерах</InfoTitleSd>
                        <InfoTextSd>
                            С помощью Кошелька вы можете создать уникальную ссылку или QR-код для онлайн-оплаты
                        </InfoTextSd>
                    </div>
                </ContentBoxSd>
                <ContentBoxSd>
                    <BoxOfImgSd>
                        <StaticImage
                            src="../../../images/selfworkers/UsePlatformSectionImage2.png"
                            alt="bot"
                            loading="eager"
                            placeholder="none"
                            formats={["auto", "webp"]}
                            width={65}
                            height={65}
                        />
                    </BoxOfImgSd>
                    <div>
                        <InfoTitleSd>Подключайте интернет-эквайринг</InfoTitleSd>
                        <InfoTextSd>
                            Настройте прием платежей и автоматическую отправку чеков на вашем сайте без открытия расчетного счета
                        </InfoTextSd>
                    </div>
                </ContentBoxSd>
            </BodyBoxSd>
        </SectionBoxSd>
    )
};

const SectionBoxSd = styled.section`
    margin-top: 100px;
    margin-bottom: 120px;

    .title {
        margin: 0 auto 24px;
        max-width: 820px;
    }

    .description {
        margin: 0 auto 40px;
        color: var(--text-secondary);
        max-width: 820px;
    }

`;

const TryBtn= styled.div`
    margin: 0 auto 80px;
    max-width: 820px;
    text-align: center;
`;

const BoxOfImgSd = styled.div`
    grid-area: icon;
    width: 65px;
    height: 65px;

    & > * {
        width: 100%;
        height: 100%;
    }

    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        margin: 0 auto;
        width: 56px;
        height: 56px;
        flex: none;
    }
`;

const BodyBoxSd = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 56px;
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    flex-direction: column;
    //
    gap: 36px;
  }
`;

const ContentBoxSd = styled.div`
  display: flex;
  flex-direction: row;
  gap: 36px;
  justify-content: center;
  
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    gap: 10px;
  }
`;

const InfoTitleSd = styled(TypographyUI.H3)`
    margin-bottom: 16px;
    color: var(--text-primary);
    @media (min-width: ${LAPTOP_MOBILE_POINTER}px) {
        width: 445px;
    }
    @media (max-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
        width: 100%;
    }
`;

const InfoTextSd = styled(TypographyUI.Body1Regular)`
  margin-bottom: 40px;
  color: var(--text-secondary);
  @media (min-width: ${LAPTOP_MOBILE_POINTER}px) {
    width: 445px;
  }
  @media (max-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    margin-bottom: 20px;
    width: 100%;
  }
`;

const ButtonSd = styled(ButtonUI.Primary)`
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    width: 240px;
  }
  @media (max-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    width: 100%;
  }
`;
